import { ChoiceChip } from "@vygruppen/spor-react";
import { useState } from "react";
import { IChoice } from "Types";

/**
 *
 * @param param0 transport mode, icons, callback for handling change in checked value
 * @returns A choicechip for a given transport mode. To be used in the iconstack
 */
export function TransportChoice({
  transportType,
  checkedIcon,
  uncheckedIcon,
  handleCheckChange,
  chipChecked = false,
}: IChoice) {
  const [checked, setChecked] = useState(false);

  return (
    <ChoiceChip
      isChecked={chipChecked}
      onChange={() => {
        setChecked(!checked);
        handleCheckChange(transportType, checked);
      }}
      icon={{ default: uncheckedIcon, checked: checkedIcon }}
    >
      {transportType}
    </ChoiceChip>
  );
}
