const Color = {
  primaryGray: "#F5F5F5",
  white: "#FFFFFF",
  hoverGreen: "#E5F4F1",
  validBlackText: "#000000",
  invalidRed: "#FF0000",
  selectedDropDownItem: "rgba(0,0,0,0.5)",
  dropDownItem: "rgba(0,0,0,1)",
  selectedItemBackground: "#B2DFD7",
  itemBackground: "#F5F5F5",
};
export default Color;
