import {
  BusFill24Icon,
  BusOutline24Icon,
  FerryFill24Icon,
  Stack,
  TrainFill24Icon,
  TrainOutline24Icon,
  FerryOutline24Icon,
  TramOutline24Icon,
  TramFill24Icon,
  SubwayOutline24Icon,
  SubwayFill24Icon,
} from "@vygruppen/spor-react";
import { TransportChoice } from "components/TransportChoice/TransportChoice";
import { ICheck } from "Types";

/**
 *
 * @param props list of allready selected modes and callback to toggle checked/unchecked
 * @returns component of styled and grouped transport-choice toggle buttons
 */
export default function IconStack(props: ICheck) {
  const handleChange = (value: string, unchecked: boolean) => {
    props.handleCheckChange(value, unchecked);
  };

  return (
    <Stack direction="column">
      <Stack direction="row">
        <TransportChoice
          chipChecked={props.checkedTransports.includes("Tog")}
          handleCheckChange={handleChange}
          transportType="Tog"
          uncheckedIcon={<TrainOutline24Icon />}
          checkedIcon={<TrainFill24Icon />}
        />
        <TransportChoice
          chipChecked={props.checkedTransports.includes("Buss")}
          handleCheckChange={handleChange}
          transportType="Buss"
          uncheckedIcon={<BusOutline24Icon />}
          checkedIcon={<BusFill24Icon />}
        />
        <TransportChoice
          chipChecked={props.checkedTransports.includes("Trikk")}
          handleCheckChange={handleChange}
          transportType="Trikk"
          uncheckedIcon={<TramOutline24Icon />}
          checkedIcon={<TramFill24Icon />}
        />
      </Stack>
      <Stack direction="row">
        <TransportChoice
          chipChecked={props.checkedTransports.includes("T-bane")}
          handleCheckChange={handleChange}
          transportType="T-bane"
          uncheckedIcon={<SubwayOutline24Icon />}
          checkedIcon={<SubwayFill24Icon />}
        />
        <TransportChoice
          chipChecked={props.checkedTransports.includes("Båt")}
          handleCheckChange={handleChange}
          transportType="Båt"
          uncheckedIcon={<FerryOutline24Icon />}
          checkedIcon={<FerryFill24Icon />}
        />
      </Stack>
    </Stack>
  );
}
