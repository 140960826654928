import { Container } from "Layouts/Container/StyledContainer";
import { Flex, Button } from "@vygruppen/spor-react";
import { useEffect, useState } from "react";
import TravelAdviceOverriding from "components/TravelAdviceOverriding/TravelAdviceOverriding";
import { RowElements } from "Layouts/RowElements/RowElements";
import { IAddAdvice, validMessageLength } from "Types";
import MessageTabs from "components/MessageTabs/MessageTabs";
import { UseAppDispatch, UseAppSelector } from "store/hooks";
import { resetAll } from "store/createTravelAdviceSlicer";
import Validator from "components/Validator/Validator";
import { useNavigate } from "react-router-dom";
import Overview from "components/Overview/Overview";
import { toast } from "react-toastify";
import TravelInformation from "components/CreateTravelAdvice/TravelInformation";
import {
  usePostTravelAdviceMutation,
  usePutTravelAdviceMutation,
} from "services/travelAdvicesApi";
import { FlatCard } from "Layouts/FlatCard/FlatCard";
import { ButtonDiv } from "Layouts/ButtonDiv/ButtonDiv";
import { PaddingStyle } from "Layouts/PaddingStyle/PaddingStyle";
import "assets/toast.css";

export default function AddTravelAdvicePage(props: IAddAdvice) {
  const dispatch = UseAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [isOpen, setOpen] = useState(false);

  let buttonText: string;
  let titleText: string;

  if (props.mode === "edit") {
    buttonText = "Lagre";
    titleText = "Rediger reiseråd";
  } else {
    buttonText = "Opprett reiseråd";
    titleText = "Nytt reiseråd";
  }

  const navigate = useNavigate();
  const travelAdvice = UseAppSelector((state) => state.createTravelAdvice);
  const tmp: string[] = [];
  const [validation, setValidation] = useState(tmp);
  const [messageLength, setMessageLength] = useState(
    validMessageLength.MESSAGE_TOO_SHORT
  );

  /**
   * Handles validation of all user input and is responsible for setting alert
   * message and opening validation modal
   */
  const handleValidate = () => {
    const validationMessages: string[] = [];
    if (
      travelAdvice.validFromTime === "Invalid date" ||
      travelAdvice.validToTime === "Invalid date" ||
      travelAdvice.internalName === ""
    ) {
      setDisabled(true);
      if (
        travelAdvice.validFromTime === "Invalid date" ||
        travelAdvice.validToTime === "Invalid date"
      ) {
        validationMessages.push("Mangler input på dato*");
      }
      if (travelAdvice.internalName === "") {
        validationMessages.push("Mangler navn*");
      }
    } else {
      setDisabled(false);
    }
    if (
      new Date(travelAdvice.validFromTime) > new Date(travelAdvice.validToTime)
    ) {
      validationMessages.push("Fra dato er større enn til dato");
    }
    if (messageLength === validMessageLength.MESSAGE_TOO_SHORT) {
      validationMessages.push("Noen språk mangler en melding til kunde");
    } else if (messageLength === validMessageLength.MESSAGE_TOO_LONG) {
      validationMessages.push("Meldingen er for lang, kan overstyres");
    }
    if (
      travelAdvice.affectedModes.length === 0 ||
      travelAdvice.affectedModes === null
    ) {
      validationMessages.push("Ingen modaliteter er valgt");
    }
    if (
      travelAdvice.toStops.length === 0 ||
      travelAdvice.fromStops.length === 0
    ) {
      validationMessages.push("Det er ingen valgte til og/eller fra stasjoner");
    }

    setValidation(validationMessages);
    validationMessages.length > 0 && setOpen(true);
    !isOpen && validationMessages.length < 1 && handleSubmit();
  };

  /**
   * Updates (put) a travel advice if the page is in edit-mode, creates
   * one (post) otherwise
   */
  function handleSubmit() {
    if (props.mode === "edit") {
      putTravelAdvice(travelAdvice);
    } else {
      postTravelAdvice(travelAdvice);
    }
  }

  const [
    postTravelAdvice,
    { isSuccess: postSuccess, isError: postError, isLoading: postIsLoading },
  ] = usePostTravelAdviceMutation();
  const [
    putTravelAdvice,
    { isSuccess: putSuccess, isError: putError, isLoading: putIsLoading },
  ] = usePutTravelAdviceMutation();

  /**
   * Toasts representing the status of the mutation
   */
  useEffect(() => {
    if (postSuccess) {
      toast("Reiserådet er opprettet", {
        type: "success",
        className: "successToast",
      });
      dispatch(resetAll());
      navigate("/");
    } else if (postError) {
      toast("Problemer oppsto ved oppretting av reiseråd", {
        type: "error",
        className: "errorToast",
      });
    }
  }, [postError, postSuccess]);

  useEffect(() => {
    if (putSuccess) {
      toast("Reiserådet er redigert", {
        type: "success",
        className: "successToast",
      });
      dispatch(resetAll());
      props.setEditOpen && props.setEditOpen(false);
    } else if (putError) {
      toast("Problemer oppsto ved redigering av reiseråd", {
        type: "error",
        className: "errorToast",
      });
    }
  }, [putError, putSuccess]);

  return (
    <Container>
      <Flex flexWrap="wrap" justifyContent="center">
        <FlatCard>
          <TravelInformation title={titleText} />
          <RowElements>
            <PaddingStyle>
              <TravelAdviceOverriding />
              <MessageTabs setMessageLength={setMessageLength} />
            </PaddingStyle>
          </RowElements>
          <ButtonDiv>
            <Overview />
            <Button
              onClick={() => {
                handleValidate();
              }}
              isLoading={postIsLoading || putIsLoading}
            >
              {buttonText}
            </Button>
            <Validator
              disabled={disabled}
              info={validation}
              isOpen={isOpen}
              setOpen={setOpen}
              handleSubmit={handleSubmit}
            />
          </ButtonDiv>
        </FlatCard>
      </Flex>
    </Container>
  );
}
