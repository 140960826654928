import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
} from "@vygruppen/spor-react";
import React, { useEffect } from "react";
import plus from "../../assets/plus.svg";
import minus from "../../assets/minus.svg";
import { UseAppDispatch, UseAppSelector } from "store/hooks";
import {
  setEngBody,
  setEngTitle,
  setNobBody,
  setNobTitle,
  setSweBody,
  setSweTitle,
} from "store/createTravelAdviceSlicer";
import Color from "assets/Color";
import { ITravelAdviceMessage, languages, validMessageLength } from "Types";
import useWindowDimensions from "common/UseWindowDimensions";
import { Screen } from "assets/Screen";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit/dist/createAction";
import { MESSAGE_LENGTH, MESSAGE_TITLE_LENGTH } from "assets/ValidInput";

interface IMessageTab {
  title: string;
  message: string;
  dispatchTitle: ActionCreatorWithPayload<string, string>;
  dispatchMessage: ActionCreatorWithPayload<string, string>;
}

function MessageTab({
  title,
  message,
  dispatchTitle,
  dispatchMessage,
}: IMessageTab) {
  const dispatch = UseAppDispatch();

  return (
    <>
      <Text>Tittel</Text>
      <FormControl
        isInvalid={title.length > MESSAGE_TITLE_LENGTH}
        marginTop={"2%"}
        marginBottom={"3%"}
      >
        <Input
          value={title}
          onChange={(e) => dispatch(dispatchTitle(e.target.value))}
          label=""
        />
        <FormErrorMessage color={Color.invalidRed}>
          Tittelen er for lang, maks {MESSAGE_TITLE_LENGTH} tegn, kan overstyres
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={message.length > MESSAGE_LENGTH}>
        <Text>Melding</Text>
        <Textarea
          label={""}
          value={message}
          onChange={(e) => {
            dispatch(dispatchMessage(e.target.value));
          }}
        />
        <FormErrorMessage color={Color.invalidRed}>
          Meldingen er for lang, maks {MESSAGE_LENGTH} tegn. kan overstyres
        </FormErrorMessage>
      </FormControl>
    </>
  );
}

/**
 *
 * @param props max length of message
 * @returns component consisting of tabs, representing languages norwegian,
 * swedish and english, for inutting a title and message
 */
function MessageTabs(props: { setMessageLength: (value: number) => void }) {
  const [isOpen, setOpen] = React.useState(false);

  const icon = isOpen ? (
    <img src={minus} alt="Nytt reiseråd" />
  ) : (
    <img src={plus} alt="Lukk reiseråd" />
  );
  const buttonColor = isOpen ? Color.hoverGreen : "white";
  const title = isOpen ? "Lukk melding til kunde" : "Melding til kunde";

  const dispatch = UseAppDispatch();
  const selector = UseAppSelector((state) => state.createTravelAdvice);
  const [dateCheck, setDateCheck] = React.useState(false);
  const { width: windowWidth } = useWindowDimensions();

  //Iterating over texts in all languages checking for valid length
  useEffect(() => {
    let key: keyof ITravelAdviceMessage;
    let longestMessage = 0;
    for (key in selector.texts) {
      const lang = selector.texts[key];
      if (lang && lang.body.length > longestMessage) {
        longestMessage = lang.body.length;
      }
    }
    if (longestMessage > MESSAGE_LENGTH) {
      props.setMessageLength(validMessageLength.MESSAGE_TOO_LONG);
    } else {
      props.setMessageLength(validMessageLength.MESSAGE_OK);
    }
  });

  /**
   * Appends the date of the travel advice to the inputted title of the message
   * @param language the language the current tab represents. Is triggerd by checking
   * the checkbox.
   */
  const dateText = (language: languages) => {
    let midMessage = " til ";
    if (language === languages.ENG) {
      midMessage = " to ";
    } else if (language === languages.SWE) {
      midMessage = " till ";
    }
    const date =
      "( " +
      selector.validFromTime.split("T")[0] +
      midMessage +
      selector.validToTime.split("T")[0] +
      " )";
    switch (language) {
      case languages.ENG:
        if (selector.texts.eng?.title !== "") {
          if (selector.texts.eng?.title.includes(date) && dateCheck) {
            dispatch(setEngTitle(selector.texts.eng?.title.split("( ")[0]));
          } else if (!dateCheck) {
            dispatch(setEngTitle(selector.texts.eng?.title + date));
          }
        }

        break;

      case languages.SWE:
        if (selector.texts.swe?.title !== "") {
          if (selector.texts.swe?.title.includes(date) && dateCheck) {
            dispatch(setSweTitle(selector.texts.swe?.title.split("( ")[0]));
          } else if (!dateCheck) {
            dispatch(setSweTitle(selector.texts.swe?.title + date));
          }
        }

        break;
      case languages.NOB:
        if (selector.texts.nob?.title !== "") {
          if (selector.texts.nob?.title.includes(date) && dateCheck) {
            dispatch(setNobTitle(selector.texts.nob?.title.split("( ")[0]));
          } else if (!dateCheck) {
            dispatch(setNobTitle(selector.texts.nob?.title + date));
          }
        }

        break;
    }
  };

  return (
    <>
      <Button
        backgroundColor={buttonColor}
        variant="additional"
        rightIcon={icon}
        onClick={() => setOpen(!isOpen)}
      >
        {title}
      </Button>
      <div
        style={{
          maxWidth:
            windowWidth > Screen.MAX_MOBILE_WIDTH
              ? Screen.PC_WIDTH
              : Screen.MOBILE_WIDTH,
        }}
      >
        {isOpen && (
          <>
            <Text style={{ paddingBottom: "1rem" }} textStyle="md">
              Melding til kunde
            </Text>
            <Tabs variant="square" colorScheme="dark" size="md">
              <TabList>
                <Tab>Norsk</Tab>
                <Tab>Svensk</Tab>
                <Tab>Engelsk</Tab>
              </TabList>
              <TabPanels>
                <TabPanel marginTop={"10%"}>
                  <MessageTab
                    title={selector.texts.nob ? selector.texts.nob.title : ""}
                    message={selector.texts.nob ? selector.texts.nob.body : ""}
                    dispatchTitle={setNobTitle}
                    dispatchMessage={setNobBody}
                  />
                </TabPanel>
                <TabPanel marginTop={"10%"}>
                  <MessageTab
                    title={selector.texts.swe ? selector.texts.swe.title : ""}
                    message={selector.texts.swe ? selector.texts.swe.body : ""}
                    dispatchTitle={setSweTitle}
                    dispatchMessage={setSweBody}
                  />
                </TabPanel>
                <TabPanel marginTop={"10%"}>
                  <MessageTab
                    title={selector.texts.eng ? selector.texts.eng.title : ""}
                    message={selector.texts.eng ? selector.texts.eng.body : ""}
                    dispatchTitle={setEngTitle}
                    dispatchMessage={setEngBody}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
            <div style={{ paddingTop: "2rem" }}>
              <Checkbox
                defaultChecked={dateCheck}
                isDisabled={
                  selector.texts.eng?.title === "" &&
                  selector.texts.swe?.title === "" &&
                  selector.texts.nob?.title === ""
                }
                onChange={() => {
                  setDateCheck(!dateCheck);
                  dateText(languages.NOB);
                  dateText(languages.SWE);
                  dateText(languages.ENG);
                }}
              >
                Legg til dato i tittel
              </Checkbox>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default MessageTabs;
