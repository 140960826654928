import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Button,
} from "@vygruppen/spor-react";
import Color from "assets/Color";
import { IValidator } from "Types";

/**
 *
 * @returns Modal for validation message notifying user of missing input
 */
export default function Validator(props: IValidator) {
  return (
    <Modal isOpen={props.isOpen} onClose={() => props.setOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Hmm noe ser ikke helt riktig ut...</ModalHeader>
        <div style={{ paddingLeft: "2rem", paddingBottom: "1rem" }}>
          <Text fontWeight="bold" size="sm">
            Felt markert med * er obligatoriske og må fylles ut.
          </Text>
        </div>
        <ModalBody>
          {props.info.map((displayMessage) => (
            <Text
              color={
                displayMessage.includes("*")
                  ? Color.invalidRed
                  : Color.validBlackText
              }
            >
              ● {displayMessage}
            </Text>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="tertiary"
            mr={3}
            onClick={() => props.setOpen(false)}
          >
            Tilbake
          </Button>

          <Button
            isDisabled={props.disabled}
            variant="primary"
            mr={3}
            onClick={() => {
              props.handleSubmit();
              props.setOpen(false);
            }}
          >
            Lag Reiseråd
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
