import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@vygruppen/spor-react";
import AddTravelAdvicePage from "pages/AddTravelAdvicePage";
import { IEditTravelAdvice } from "Types";
import { useEffect } from "react";
import { resetAll } from "store/createTravelAdviceSlicer";
import { UseAppDispatch } from "store/hooks";

/**
 *
 * @param props Tells the AddTravelAdvice component that is is in "edit-mode"
 * @returns modal consisting of the "add travel advice" gui, but in "edit-mode"
 */
export default function EditTravelAdvice(props: IEditTravelAdvice) {
  const dispatch = UseAppDispatch();

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
        dispatch(resetAll());
      }}
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent maxWidth={"1086px"}>
        <ModalCloseButton />
        <ModalBody>
          <AddTravelAdvicePage mode={"edit"} setEditOpen={props.setIsOpen} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
