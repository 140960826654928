import {
  Button,
  Textarea,
  Text,
  FormControl,
  FormErrorMessage,
} from "@vygruppen/spor-react";
import plus from "../../assets/plus.svg";
import minus from "../../assets/minus.svg";
import React from "react";
import SearchAndMultiSelect from "../SearchAndMultiSelect/SearchAndMultiSelect";
import IconStack from "components/IconStack/IconStack";
import { selector } from "Types";
import { UseAppDispatch, UseAppSelector } from "store/hooks";
import {
  addNeverAllowMode,
  removeNeverAllowMode,
  setInternalComment,
} from "store/createTravelAdviceSlicer";
import { Screen } from "assets/Screen";
import useWindowDimensions from "common/UseWindowDimensions";
import Color from "assets/Color";
import { INTERNAL_COMMENT_LENGTH } from "assets/ValidInput";

/**
 *
 * @returns component for overriding of the travel advice
 */
function TravelAdviceOverriding() {
  const [isOpen, setOpen] = React.useState(false);

  const dispatch = UseAppDispatch();
  const internalComment = UseAppSelector(
    (state) => state.createTravelAdvice.internalComment
  );
  const neverAllowModes = UseAppSelector(
    (state) => state.createTravelAdvice.neverAllowModes
  );
  const handleCheckChange = (value: string, unchecked: Boolean) => {
    unchecked
      ? dispatch(removeNeverAllowMode(value))
      : dispatch(addNeverAllowMode(value));
  };
  const icon = isOpen ? (
    <img src={minus} alt="Nytt reiseråd" />
  ) : (
    <img src={plus} alt="Lukk reiseråd" />
  );
  const buttonColor = isOpen ? "#E5F4F1" : "white";
  const title = isOpen
    ? "Lukk overstyring av reisesøk"
    : "Overstyring av reisesøk";
  const { width: windowWidth } = useWindowDimensions();
  return (
    <div
      style={{
        minWidth:
          windowWidth > Screen.MAX_MOBILE_WIDTH
            ? Screen.PC_WIDTH
            : Screen.MOBILE_WIDTH,
      }}
    >
      <Button
        minWidth={
          windowWidth > Screen.MAX_MOBILE_WIDTH
            ? Screen.PC_WIDTH
            : Screen.MOBILE_WIDTH
        }
        backgroundColor={buttonColor}
        variant="additional"
        rightIcon={icon}
        onClick={() => setOpen(!isOpen)}
      >
        {title}
      </Button>
      {isOpen && (
        <div>
          <Text paddingTop="2rem" textStyle="sm">
            Ekskluder reiser med modaliteter
          </Text>
          <IconStack
            checkedTransports={neverAllowModes}
            handleCheckChange={handleCheckChange}
          />
          <Text textStyle="sm" marginTop={"10%"}>
            Eksluder reiser med selskap
          </Text>
          <SearchAndMultiSelect selector={selector.authorities} />
          <Text textStyle="sm" marginTop={"10%"}>
            Ekskluder reiser med linjer
          </Text>
          <SearchAndMultiSelect selector={selector.lines} />
          <FormControl
            isInvalid={internalComment.length > INTERNAL_COMMENT_LENGTH}
            paddingBottom="48px"
          >
            <Textarea
              label="Intern kommentar"
              placeholder="Skriv inn tekst her."
              marginTop={"7%"}
              value={internalComment}
              onChange={(event) =>
                dispatch(setInternalComment(event.target.value))
              }
            />
            <FormErrorMessage color={Color.invalidRed}>
              Kommentaren er for lang, maks {INTERNAL_COMMENT_LENGTH} tegn, kan
              overstyres
            </FormErrorMessage>
          </FormControl>
        </div>
      )}
    </div>
  );
}

export default TravelAdviceOverriding;
