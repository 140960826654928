import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "api/apiClient";
import { getApiUrl } from "api/config";
import { IFavorite } from "Types";

/**
 * Slice containing queries and resulting query data for /favorites endpoint
 */
export const favoritesApi = createApi({
  reducerPath: "favoritesApi",
  baseQuery: axiosBaseQuery({
    baseUrl: getApiUrl() + "/favorites",
  }),
  tagTypes: ["favorites"],
  endpoints: (builder) => ({
    getFavorites: builder.query<IFavorite[], void>({
      query: () => ({ url: ``, method: "get" }),
      providesTags: ["favorites"],
    }),
    postFavorite: builder.mutation<IFavorite, IFavorite>({
      query: (favorite: IFavorite) => ({
        url: ``,
        data: favorite,
        method: "post",
      }),
      async onQueryStarted(_favorite, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(
          favoritesApi.util.updateQueryData(
            "getFavorites",
            undefined,
            (draft) => {
              draft.unshift(data);
            }
          )
        );
      },
    }),

    deleteFavorite: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            favoritesApi.util.updateQueryData(
              "getFavorites",
              undefined,
              (draft) => {
                const newDraft = draft.filter((favorite) => favorite.id !== id);
                return newDraft;
              }
            )
          );
        } catch (error) {
          this.invalidatesTags = ["favorites"];
        }
      },
    }),
  }),
});

export const {
  useGetFavoritesQuery,
  usePostFavoriteMutation,
  useDeleteFavoriteMutation,
} = favoritesApi;
