import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "api/apiClient";
import { getApiUrl } from "api/config";
import { IAuthorities, ILines, IStation } from "Types";

/**
 * Slice containing queries and resulting query data for /travel-details/ endpoint
 */
export const travelDetailsApi = createApi({
  reducerPath: "travelDetailsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: getApiUrl() + "/travel-details/",
  }),
  endpoints: (builder) => ({
    getLines: builder.query<ILines[], void>({
      query: () => ({ url: `lines`, method: "get" }),
    }),
    getStopPlaces: builder.query<IStation[], void>({
      query: () => ({ url: `train-stops`, method: "get" }),
    }),
    getAuthorities: builder.query<IAuthorities[], void>({
      query: () => ({ url: `authorities`, method: "get" }),
    }),
    getTrainLines: builder.query<ILines[], void>({
      query: () => ({ url: `train-lines`, method: "get" }),
    }),
    getStationsForLine: builder.query<IStation[], string>({
      query: (lineId: string) => ({
        url: `stops_from_line/${lineId}`,
        method: "get",
      }),
    }),
  }),
});

export const {
  useGetAuthoritiesQuery,
  useGetLinesQuery,
  useGetStopPlacesQuery,
  useGetTrainLinesQuery,
  useLazyGetStationsForLineQuery,
} = travelDetailsApi;
