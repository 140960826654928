import {
  Button,
  ChoiceChip,
  FormControl,
  Stack,
  SuccessOutline24Icon,
  TextLink,
} from "@vygruppen/spor-react";
import { useState } from "react";
import { SearchInput } from "@vygruppen/spor-react";
import { selector, ISelector } from "Types";
import { UseAppDispatch, UseAppSelector } from "store/hooks";
import {
  addAuthority,
  addFromStop,
  addLine,
  addToStop,
  removeAuthority,
  removeFromStop,
  removeLine,
  removeToStop,
} from "store/createTravelAdviceSlicer";
import Color from "assets/Color";
import "./styledSearch.css";
import { FixedSizeList as List } from "react-window";
import SaveAsFavorite from "../SaveAsFavorite/SaveAsFavorite";
import {
  useGetAuthoritiesQuery,
  useGetLinesQuery,
  useGetStopPlacesQuery,
} from "services/travelDetailsApi";
import useWindowDimensions from "common/UseWindowDimensions";
import { SearchResults } from "Layouts/SearchResults/SearchResults";
import { Screen } from "assets/Screen";
/**
 * Search component with multi-select functionality.
 * Renders a search-input field and a scrollable list of
 * selectable matches for the search-word.
 * Adapted to data of types stop places, lines and authorities
 */

function SearchAndMultiSelect(props: ISelector) {
  const ITEM_HEIGTH: number = 35;
  const MAX_SCROLL_HEIGHT: number = 300;
  const dispatch = UseAppDispatch();
  const [value, setValue] = useState("");
  const { width: windowWidth } = useWindowDimensions();

  const { data: stopPlaces = [] } = useGetStopPlacesQuery();
  const { data: auth = [] } = useGetAuthoritiesQuery();
  const { data: lines = [] } = useGetLinesQuery();
  const selectedFromStops = UseAppSelector(
    (state) => state.createTravelAdvice.fromStops
  );
  const selectedToStops = UseAppSelector(
    (state) => state.createTravelAdvice.toStops
  );
  const selectedLines = UseAppSelector(
    (state) => state.createTravelAdvice.neverAllowLines
  );
  const selectedAuthorities = UseAppSelector(
    (state) => state.createTravelAdvice.neverAllowAuthorities
  );

  /**
   * Removes an element from the global state.
   * @param id the id of the element to be removed.
   */
  function handleRemove(id: string) {
    switch (props.selector) {
      case selector.fromStops:
        const fromStop = stopPlaces?.find((item) => item.nsrCode === id);
        if (fromStop) {
          dispatch(removeFromStop(fromStop));
        }
        break;
      case selector.toStops:
        const toStop = stopPlaces?.find((item) => item.nsrCode === id);
        if (toStop) {
          dispatch(removeToStop(toStop));
        }
        break;
      case selector.authorities:
        dispatch(removeAuthority(id));
        break;
      case selector.lines:
        const line = lines?.find((item) => item.id === id);

        if (line) {
          dispatch(removeLine(line.id));
        }
        break;
    }
  }

  /**
   * Removes all of the selected elements from the global state
   */
  function removeAllSelected() {
    if (props.selector === "fromStops") {
      selectedFromStops.forEach((stop) => dispatch(removeFromStop(stop)));
    } else {
      selectedToStops.forEach((stop) => dispatch(removeToStop(stop)));
    }
  }

  /**
   * Add a clicked list element to the "selected state"
   * @param id id of the element to add
   */
  function handleSelect(id: string) {
    switch (props.selector) {
      case selector.fromStops:
        const fromStop = stopPlaces?.find((item) => item.nsrCode === id);
        if (fromStop) {
          dispatch(addFromStop(fromStop));
        }
        break;
      case selector.toStops:
        const toStop = stopPlaces?.find((item) => item.nsrCode === id);
        if (toStop) {
          dispatch(addToStop(toStop));
        }
        break;
      case selector.authorities:
        dispatch(addAuthority(id));
        break;
      case selector.lines:
        const line = lines?.find((item) => item.id === id);
        if (line) {
          dispatch(addLine(line.id));
        }
        break;
    }
  }

  /**
   *
   * @returns A choicechip-component for all elements in the "selected state".
   * The chips can be clicked to remove them from the "selected state"
   */
  function renderChips() {
    switch (props.selector) {
      case selector.fromStops:
        return selectedFromStops.map((stop) => (
          <ChoiceChip
            className="choice_chip"
            key={stop.nsrCode}
            variant="filter"
            isChecked
            defaultChecked
            onChange={() => handleRemove(stop.nsrCode)}
          >
            {stop.name}
          </ChoiceChip>
        ));

      case selector.toStops:
        return selectedToStops.map((stop) => (
          <ChoiceChip
            className="choice_chip"
            key={stop.nsrCode}
            variant="filter"
            isChecked
            defaultChecked
            onChange={() => handleRemove(stop.nsrCode)}
          >
            {stop.name}
          </ChoiceChip>
        ));

      case selector.authorities:
        return selectedAuthorities.map((authority) => (
          <ChoiceChip
            size="lg"
            className="choice_chip"
            key={authority}
            variant="filter"
            isChecked
            defaultChecked
            onChange={() => handleRemove(authority)}
          >
            {authority}
          </ChoiceChip>
        ));

      case selector.lines:
        return selectedLines.map((line) => (
          <ChoiceChip
            className="choice_chip"
            key={line}
            variant="filter"
            isChecked
            defaultChecked
            onChange={() => handleRemove(line)}
          >
            {line}
          </ChoiceChip>
        ));
    }
  }

  /**
   *
   * @param param0 paged items, their relative index and their style
   * @returns paged button-comonents as list items matching the search word
   * in the multiselect dropdown. Buttons can be clicked to add the item to
   * the "selected-state"
   */
  const Item = ({ data, index, style }: any) => {
    if (!data[index]) {
      return null;
    }
    let selected: boolean;
    let listElements: string;

    switch (props.selector) {
      case selector.fromStops:
      case selector.toStops:
        if (props.selector === selector.fromStops) {
          selected = selectedFromStops
            .map((stop) => stop.nsrCode)
            .includes(data[index].nsrCode);
        } else {
          selected = selectedToStops
            .map((stop) => stop.nsrCode)
            .includes(data[index].nsrCode);
        }
        return (
          <Button
            style={style}
            className="selectBtn"
            flex="1"
            justifyContent="space-between"
            paddingLeft={"4%"}
            minW={"100%"}
            borderRadius={"0px"}
            type="button"
            variant={"ghost"}
            color={selected ? Color.selectedDropDownItem : Color.dropDownItem}
            rightIcon={selected ? <SuccessOutline24Icon /> : <></>}
            onClick={
              selected
                ? () => handleRemove(data[index].nsrCode)
                : () => handleSelect(data[index].nsrCode)
            }
          >
            {data[index].name}
          </Button>
        );

      case selector.lines:
        selected = selectedLines.includes(data[index].id);
        listElements = data[index].id;
        break;
      case selector.authorities:
        selected = selectedAuthorities.includes(data[index].id);
        listElements = data[index].id;
        break;
    }

    return (
      <Button
        style={style}
        className="selectBtn"
        flex="1"
        justifyContent="space-between"
        paddingLeft={"4%"}
        minW={"100%"}
        borderRadius={"0px"}
        type="button"
        variant={"ghost"}
        color={selected ? Color.selectedDropDownItem : Color.dropDownItem}
        rightIcon={selected ? <SuccessOutline24Icon /> : <></>}
        onClick={
          selected
            ? () => handleRemove(listElements)
            : () => handleSelect(listElements)
        }
      >
        {listElements}
      </Button>
    );
  };

  /**
   * Finds the elements matching the search
   * @returns a scrollable drop-down component with selectable items
   */
  const renderListItems = () => {
    if (value === "") {
      return;
    }
    let match;
    let height;
    switch (props.selector) {
      case selector.fromStops:
      case selector.toStops:
        match = stopPlaces.filter((stop) =>
          stop.name.toLowerCase().includes(value.toLowerCase())
        );
        break;

      case selector.authorities:
        match = auth.filter((auth) =>
          auth.id.toLowerCase().includes(value.toLowerCase())
        );
        break;

      case selector.lines:
        match = lines.filter(
          (line) =>
            (line.publicCode !== null &&
              line.publicCode.toLowerCase().includes(value.toLowerCase())) ||
            line.id.toLowerCase().includes(value.toLowerCase())
        );
        break;
    }
    height =
      match.length * ITEM_HEIGTH < MAX_SCROLL_HEIGHT
        ? match.length * ITEM_HEIGTH
        : MAX_SCROLL_HEIGHT;

    return (
      lines.length > 0 && (
        <List
          className="list"
          itemData={match}
          height={height}
          itemCount={match.length}
          itemSize={ITEM_HEIGTH}
          width={"100%"}
        >
          {Item}
        </List>
      )
    );
  };

  return (
    <div
      style={{
        width:
          windowWidth > Screen.MAX_MOBILE_WIDTH
            ? Screen.PC_WIDTH
            : Screen.MOBILE_WIDTH,
      }}
    >
      <FormControl>
        <SearchInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onReset={() => setValue("")}
        />
      </FormControl>
      <SearchResults>{renderListItems()}</SearchResults>
      <Stack direction="column" marginTop={"6%"}>
        {renderChips()}
      </Stack>
      <div style={{ marginTop: "15%", marginLeft: "10%" }}>
        {props.selector === selector.fromStops && selectedFromStops.length > 0 && (
          <div style={{ flexDirection: "column" }}>
            <div style={{ marginBottom: "5%" }}>
              <SaveAsFavorite selector={selector.fromStops} />
            </div>
            <div>
              <TextLink size={"xs"} onClick={() => removeAllSelected()}>
                Fjern alle stasjoner
              </TextLink>
            </div>
          </div>
        )}
        {props.selector === selector.toStops && selectedToStops.length > 0 && (
          <div style={{ flexDirection: "column" }}>
            <div style={{ marginBottom: "5%" }}>
              <SaveAsFavorite selector={selector.toStops} />
            </div>
            <div>
              <TextLink size={"xs"} onClick={() => removeAllSelected()}>
                Fjern alle stasjoner
              </TextLink>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchAndMultiSelect;
