import TravelAdviceList from "components/TravelAdviceList/TravelAdviceList";
import {
  Button,
  Text,
  Input,
  SearchOutline24Icon,
} from "@vygruppen/spor-react";
import plus from "../assets/plus.svg";
import { Container } from "Layouts/Container/StyledContainer";
import { FlexDiv } from "Layouts/FlexDiv/FlexDiv";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Filter from "components/Filter/Filter";
import { ContentContainer } from "Layouts/ContentContainer/ContentContainer";

/**
 * @returns Page with list of all active and archived travel advices
 */
function ViewTravelAdvicePage() {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");

  return (
    <Container>
      <ContentContainer>
        <div style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
          <Input
            autoFocus
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            leftIcon={<SearchOutline24Icon />}
            label="Søk på internt navn eller kommentar"
          />
          <Filter />
        </div>
        <FlexDiv>
          <Text textStyle="lg">Aktive Reiseråd</Text>
          <Button
            onClick={() => navigate("/addAdvice")}
            variant="additional"
            rightIcon={<img src={plus} alt="Nytt reiseråd" />}
          >
            Nytt Reiseråd
          </Button>
        </FlexDiv>
        <TravelAdviceList view={"active"} searchText={searchValue} />
        <FlexDiv>
          <Text textStyle="lg">Arkiverte Reiseråd</Text>
        </FlexDiv>
        <TravelAdviceList view={"archived"} searchText={searchValue} />
      </ContentContainer>
    </Container>
  );
}
export default ViewTravelAdvicePage;
