import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Tbody,
  Td,
  Tr,
  Th,
} from "@vygruppen/spor-react";
import moment from "moment";
import React from "react";
import { UseAppSelector } from "store/hooks";
import { IStation } from "Types";
import { formatDateToLocaleString } from "../../common/formatDate";

/**
 *
 * @returns A component to display all the currently selected parameters
 * for a travel advice "in the making"
 */
export default function Overview() {
  const [isOpen, setOpen] = React.useState(false);
  moment.locale("nb");

  const state = UseAppSelector((state) => state.createTravelAdvice);

  function getStopNames(stops: IStation[]) {
    return stops.map((stop) => stop.name);
  }

  return (
    <>
      <Button variant="secondary" onClick={() => setOpen(true)}>
        Se over
      </Button>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Se over reiseråd</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table marginTop={"4%"} marginBottom={"7%"}>
              <Tbody>
                <Tr>
                  <Th>Navn:</Th>
                  <Td maxWidth={300}>{state.internalName}</Td>
                </Tr>
                <Tr>
                  <Th>Gyldig fra:</Th>
                  <Td>
                    {formatDateToLocaleString(new Date(state.validFromTime))}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Gyldig til:</Th>
                  <Td>
                    {formatDateToLocaleString(new Date(state.validFromTime))}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Påvirkede modalideter:</Th>
                  <Td>{state.affectedModes.join(", ")}</Td>
                </Tr>
                <Tr>
                  <Th>Fra stasjoner:</Th>
                  <Td>{getStopNames(state.fromStops).join(", ")}</Td>
                </Tr>
                <Tr>
                  <Th>Til stasjoner:</Th>
                  <Td>{getStopNames(state.toStops).join(", ")}</Td>
                </Tr>
                <Tr>
                  <Th>Ekskluder reiser med modaliteter: </Th>
                  <Td>{state.neverAllowModes.join(", ")}</Td>
                </Tr>
                <Tr>
                  <Th>Ekskluder reiser med selskap: </Th>
                  <Td>{state.neverAllowAuthorities.join(", ")}</Td>
                </Tr>
                <Tr>
                  <Th>Ekskluder reiser med linjer: </Th>
                  <Td>{state.neverAllowLines.join(", ")}</Td>
                </Tr>
                <Tr>
                  <Th>Intern kommentar: </Th>
                  <Td maxWidth={300}>{state.internalComment}</Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button variant="tertiary" mr={3} onClick={() => setOpen(false)}>
              Lukk
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
