import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "api/apiClient";
import { getApiUrl } from "api/config";
import { ITravelAdvice } from "Types";

/**
 * Slice containing queries and resulting query data for /travel-advices/ endpoint
 */
export const travelAdvicesApi = createApi({
  reducerPath: "travelAdvicesApi",
  baseQuery: axiosBaseQuery({
    baseUrl: getApiUrl() + "/travel-advices/",
  }),
  tagTypes: ["travelAdvices"],
  endpoints: (builder) => ({
    getTravelAdvices: builder.query<ITravelAdvice[], void>({
      query: () => ({ url: ``, method: "get" }),
      providesTags: ["travelAdvices"],
    }),
    postTravelAdvice: builder.mutation<ITravelAdvice, ITravelAdvice>({
      query: (travelAdvice: ITravelAdvice) => ({
        url: "",
        method: "post",
        data: travelAdvice,
      }),
      async onQueryStarted(_travelAdvice, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(
          travelAdvicesApi.util.updateQueryData(
            "getTravelAdvices",
            undefined,
            (draft) => {
              draft.unshift(data);
            }
          )
        );
      },
    }),
    putTravelAdvice: builder.mutation<ITravelAdvice, ITravelAdvice>({
      query: (travelAdvice: ITravelAdvice) => ({
        url: "",
        method: "put",
        data: travelAdvice,
      }),
      async onQueryStarted(travelAdvice, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            travelAdvicesApi.util.updateQueryData(
              "getTravelAdvices",
              undefined,
              (draft) => {
                const index = draft.findIndex(
                  (advice) => advice.id === travelAdvice.id
                );
                draft[index] = travelAdvice;
              }
            )
          );
        } catch (error) {
          this.invalidatesTags = ["travelAdvices"];
        }
      },
    }),
  }),
});

export const {
  useGetTravelAdvicesQuery,
  usePostTravelAdviceMutation,
  usePutTravelAdviceMutation,
} = travelAdvicesApi;
