import logo from "../../assets/vy-logo-black-green.svg";
import logoutImg from "../../assets/logoutImg.svg";
import styled from "styled-components";
import Color from "assets/Color";
import { Button } from "@vygruppen/spor-react";
import { useNavigate } from "react-router-dom";
import useLoginFlow from "auth/useLoginFlow";

const Container = styled.div`
  background-color: ${Color.primaryGray};
  padding-bottom: 3rem;
`;

const InnerContainer = styled.div`
  background-color: ${Color.white};
  padding: 1rem;
  display: flex;
  min-width: 100%;
  justify-content: space-between;
`;

function Header() {
  let navigate = useNavigate();
  const { logout } = useLoginFlow(false);
  return (
    <Container>
      <InnerContainer>
        <img
          src={logo}
          alt="VY logo"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
        <Button
          onClick={() => logout()}
          variant="additional"
          leftIcon={<img src={logoutImg} alt="logg ut" />}
        >
          Logg ut
        </Button>
      </InnerContainer>
    </Container>
  );
}

export default Header;
