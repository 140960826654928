import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  WarningOutline30Icon,
} from "@vygruppen/spor-react";
import { RowElements } from "Layouts/RowElements/RowElements";
import { IDeletePopup } from "Types";

export default function popup(props: IDeletePopup) {
  function handleOk() {
    if (props.id) {
      props.handleDelete(props.id);
    }
    props.setIsOpen(false);
  }

  return (
    <Modal isOpen={props.isOpen} onClose={() => props.setIsOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <RowElements>
            <WarningOutline30Icon />
            <Text>Vil du slette favoritten?</Text>
          </RowElements>
        </ModalHeader>

        <ModalBody>
          <Text>
            Hvis du trykker ja vil favoritten slettes permanent, og vil ikke
            kunne brukes senere
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="tertiary"
            mr={3}
            onClick={() => {
              props.setIsOpen(false);
            }}
          >
            Avbryt
          </Button>

          <Button variant="primary" mr={3} onClick={() => handleOk()}>
            Ja
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
