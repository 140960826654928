import { Input } from "@vygruppen/spor-react";
import useWindowDimensions from "common/UseWindowDimensions";
import { IDateTime } from "Types";
import { Screen } from "assets/Screen";

export function Calender(props: IDateTime) {
  const { width: windowWidth } = useWindowDimensions();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: windowWidth > Screen.MAX_MOBILE_WIDTH ? "row" : "column",
        gap: 10,
      }}
    >
      <div>
        <Input
          value={props.date}
          onChange={(event) => props.setDate(event.target.value)}
          type="date"
          label="Dato"
          maxWidth={176}
          lang="nb-NO"
        />
      </div>
      <Input
        value={props.time}
        onChange={(event) => props.setTime(event.target.value)}
        type="time"
        label="Tid"
        maxWidth={125}
        lang="nb-NO"
      />
    </div>
  );
}
