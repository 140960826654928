import { getAuthorization } from "api/getAuthorization";
import { useRemoteData } from "api/remoteData";
import useLoginFlow from "auth/useLoginFlow";
import React from "react";
import { Loading } from "components/Loading/Loading";
import { SubTitle } from "Layouts/Text/StyledTitles";
import { Button } from "./Layouts/Buttons/button";
import { RoutePage } from "RoutePage";
import Header from "Layouts/Header/Header";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const AuthenticatedApp: React.FC = () => {
  const { remoteData: userData } = useRemoteData(getAuthorization());

  const getContent = () => {
    switch (userData.status) {
      case "Success":
        return (
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <BrowserRouter>
                <Header />
                <RoutePage />
                <ToastContainer />
              </BrowserRouter>
            </PersistGate>
          </Provider>
        );
      case "NotAsked":
      case "Refreshing":
      case "ShouldRefresh":
      case "Loading":
        return <VerifyUser />;
      case "Failure":
        return <UnauthorizedUser />;
    }
  };

  return getContent();
};

const VerifyUser: React.FC = () => {
  return (
    <div className="App-container">
      <p style={{ margin: 0, fontSize: "1rem" }}>Henter brukerdata..</p>
      <Loading width="20rem" />
    </div>
  );
};

const UnauthorizedUser: React.FC = () => {
  const { logout } = useLoginFlow();
  return (
    <div className="App-container">
      <SubTitle>OBS!</SubTitle>
      <p style={{ margin: "0 0 1rem", fontSize: "1rem" }}>
        Du har ikke tilgang
      </p>
      <Button type="button" onClick={logout}>
        Logg ut
      </Button>
    </div>
  );
};
