import { Text } from "@vygruppen/spor-react";
import { ICalenderCombo } from "Types";
import { Calender } from "../Calender/Calender";

/**
 *
 * @param props
 * @returns a component consisting of two styled and grouped "date pickers"
 */
export function CalenderCombination(props: ICalenderCombo) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ paddingBottom: "2rem" }}>
        <Text pb="6px" textStyle="sm">
          Gyldig fra*
        </Text>
        <Calender {...props.start} />
      </div>
      <div>
        <Text pb="6px" textStyle="sm">
          Gyldig til*
        </Text>
        <Calender {...props.end} />
      </div>
    </div>
  );
}
