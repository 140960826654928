import Color from "assets/Color";
import styled from "styled-components";

export const FlatCard = styled.div`
  background-color: ${Color.white};
  rounded-radius: 20px;
  padding: 24px;
  display: flex;
  border-radius: 6px;
  gap: 48px;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: 1086px;
  padding-bottom: 48px;
`;
