import { ReactElement, ReactNode } from "react";

export interface ITravelAdvice {
  id?: number;
  validFromTime: string;
  validToTime: string;
  affectedModes: string[];
  fromStops: IStation[];
  toStops: IStation[];
  texts: ITravelAdviceMessage;
  neverAllowModes: string[];
  neverAllowAuthorities: string[];
  neverAllowLines: string[];
  internalComment: string;
  internalName: string;
  isArchived: boolean;
}

export interface IStation {
  nsrCode: string;
  name: string;
  transportMode?: string[];
}

export enum validMessageLength {
  MESSAGE_TOO_SHORT,
  MESSAGE_OK,
  MESSAGE_TOO_LONG,
}

export enum languages {
  ENG,
  NOB,
  SWE,
}

export interface ITravelAdviceMessage {
  nob?: IMessage;
  eng?: IMessage;
  swe?: IMessage;
}

export interface ITravelDetail {
  detail: string;
  value: string;
  title?: string;
}

export interface IMessage {
  title: string;
  body: string;
}

export interface IAuthorities {
  id: string;
}

export interface ILines {
  id: string;
  publicCode: string;
}

export interface IFavorite {
  id?: number;
  name: string;
  stops: IStation[];
}

export interface ISelector {
  selector: selector;
}

export interface IIconButton {
  icon: ReactElement;
  label: string;
  isLoading?: boolean;
  onClick: () => void;
}
export interface IEditTravelAdvice {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export interface IAddAdvice {
  mode?: string;
  setEditOpen?: (value: boolean) => void;
}
export interface IDateTime {
  date: string;
  setTime: React.Dispatch<React.SetStateAction<string>>;
  time: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
}
export interface ICalenderCombo {
  start: IDateTime;
  end: IDateTime;
}
export interface ICheck {
  handleCheckChange: (value: string, unchecked: boolean) => void;
  checkedTransports: string[];
}
export interface IChoice {
  transportType: string;
  checkedIcon?: ReactNode;
  uncheckedIcon?: ReactNode;
  handleCheckChange: (value: string, unchecked: boolean) => void;
  chipChecked: boolean;
}
export interface IValidator {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  info: string[];
  disabled: boolean;
  handleSubmit: () => void;
}

export interface ISearchData {
  searchText: string;
  view: string;
}

export interface IDeletePopup {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleDelete: (id: number) => void;
  id?: number;
}

export interface IFiltering {
  sortType: string;
  sortOrder: boolean;
  toDate: string;
  fromDate: string;
  affectedModes: string[];
}

export enum selector {
  fromStops = "fromStops",
  toStops = "toStops",
  lines = "lines",
  authorities = "authorities",
}
