import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStation, ITravelAdvice, ITravelAdviceMessage } from "Types";

/**
 * Initial state of the slice is an empty travel advice
 */
const initialState: ITravelAdvice = {
  validFromTime: new Date().toISOString(),
  validToTime: new Date().toISOString(),
  affectedModes: [],
  fromStops: [],
  toStops: [],
  texts: {
    nob: { title: "", body: "" },
    swe: { title: "", body: "" },
    eng: { title: "", body: "" },
  },
  neverAllowModes: [],
  neverAllowAuthorities: [],
  neverAllowLines: [],
  internalComment: "",
  internalName: "",
  isArchived: false,
};

/**
 * Define reducers for manipulating the global state "data slice".
 * This slice is used when creating/editing a travel advice
 */
export const createTravelAdviceSlicer = createSlice({
  name: "createTravelAdvice",
  initialState,
  reducers: {
    resetAll: () => initialState,
    setNewState: (_state, action: PayloadAction<ITravelAdvice>) => {
      return { ...action.payload };
    },
    setValidFromTime: (state, action: PayloadAction<string>) => {
      state.validFromTime = action.payload;
    },
    setValidToTime: (state, action: PayloadAction<string>) => {
      state.validToTime = action.payload;
    },
    addAffectedMode: (state, action: PayloadAction<string>) => {
      if (!state.affectedModes.includes(action.payload)) {
        state.affectedModes.push(action.payload);
      }
    },
    removeAffectedMode: (state, action: PayloadAction<string>) => {
      state.affectedModes = state.affectedModes.filter(
        (mode) => mode !== action.payload
      );
    },
    removeFromStop: (state, action: PayloadAction<IStation>) => {
      state.fromStops = state.fromStops.filter(
        (station) => station.nsrCode !== action.payload.nsrCode
      );
    },
    addFromStop: (state, action: PayloadAction<IStation>) => {
      const stationAlreadyAdded = state.fromStops.find(
        (station) => station.nsrCode === action.payload.nsrCode
      );
      if (!stationAlreadyAdded) {
        state.fromStops.push(action.payload);
      }
    },
    removeToStop: (state, action: PayloadAction<IStation>) => {
      state.toStops = state.toStops.filter(
        (station) => station.nsrCode !== action.payload.nsrCode
      );
    },
    addToStop: (state, action: PayloadAction<IStation>) => {
      const stationAlreadyAdded = state.toStops.find(
        (station) => station.nsrCode === action.payload.nsrCode
      );
      if (!stationAlreadyAdded) {
        state.toStops.push(action.payload);
      }
    },
    setTexts: (state, action: PayloadAction<ITravelAdviceMessage>) => {
      state.texts = action.payload;
    },
    setNobTitle: (state, action: PayloadAction<string>) => {
      if (state.texts.nob) {
        state.texts.nob.title = action.payload;
      }
    },
    setSweTitle: (state, action: PayloadAction<string>) => {
      if (state.texts.swe) {
        state.texts.swe.title = action.payload;
      }
    },
    setEngTitle: (state, action: PayloadAction<string>) => {
      if (state.texts.eng) {
        state.texts.eng.title = action.payload;
      }
    },
    setNobBody: (state, action: PayloadAction<string>) => {
      if (state.texts.nob) {
        state.texts.nob.body = action.payload;
      }
    },
    setSweBody: (state, action: PayloadAction<string>) => {
      if (state.texts.swe) {
        state.texts.swe.body = action.payload;
      }
    },
    setEngBody: (state, action: PayloadAction<string>) => {
      if (state.texts.eng) {
        state.texts.eng.body = action.payload;
      }
    },
    addNeverAllowMode: (state, action: PayloadAction<string>) => {
      if (!state.neverAllowModes.includes(action.payload)) {
        state.neverAllowModes.push(action.payload);
      }
    },
    removeNeverAllowMode: (state, action: PayloadAction<string>) => {
      state.neverAllowModes = state.neverAllowModes.filter(
        (mode) => mode !== action.payload
      );
    },
    removeAuthority: (state, action: PayloadAction<string>) => {
      state.neverAllowAuthorities = state.neverAllowAuthorities.filter(
        (authority) => authority !== action.payload
      );
    },
    addAuthority: (state, action: PayloadAction<string>) => {
      if (!state.neverAllowAuthorities.includes(action.payload)) {
        state.neverAllowAuthorities.push(action.payload);
      }
    },
    removeLine: (state, action: PayloadAction<string>) => {
      state.neverAllowLines = state.neverAllowLines.filter(
        (line) => line !== action.payload
      );
    },
    addLine: (state, action: PayloadAction<string>) => {
      if (!state.neverAllowLines.includes(action.payload)) {
        state.neverAllowLines.push(action.payload);
      }
    },
    setInternalComment: (state, action: PayloadAction<string>) => {
      state.internalComment = action.payload;
    },
    setInternalName: (state, action: PayloadAction<string>) => {
      state.internalName = action.payload;
    },
  },
});

export const {
  setEngBody,
  setEngTitle,
  setNobBody,
  setNobTitle,
  setSweBody,
  setSweTitle,
  addLine,
  removeLine,
  addAuthority,
  removeAuthority,
  removeToStop,
  addToStop,
  addFromStop,
  removeFromStop,
  setValidFromTime,
  setValidToTime,
  addAffectedMode,
  removeAffectedMode,
  addNeverAllowMode,
  removeNeverAllowMode,
  setTexts,
  setInternalComment,
  resetAll,
  setNewState,
  setInternalName,
} = createTravelAdviceSlicer.actions;

export default createTravelAdviceSlicer.reducer;
