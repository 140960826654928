import AddTravelAdvicePage from "pages/AddTravelAdvicePage";
import ViewTravelAdvicePage from "pages/ViewTravelAdvicePage";
import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";

export const RoutePage = () => {
  return (
    <Routes>
      <Route path="/" element={<ViewTravelAdvicePage />} />
      <Route path="/addAdvice" element={<AddTravelAdvicePage />} />
      <Route path="*" element={<Navigate to="/" />} /> */
    </Routes>
  );
};
