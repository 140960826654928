import {
  Text,
  SimplePopover,
  InformationOutline30Icon,
  FormControl,
  FormErrorMessage,
  Input,
} from "@vygruppen/spor-react";
import IconStack from "components/IconStack/IconStack";
import { useEffect, useState } from "react";
import SearchAndMultiSelect from "components/SearchAndMultiSelect/SearchAndMultiSelect";
import { RowElements } from "Layouts/RowElements/RowElements";
import { selector } from "Types";
import { CalenderCombination } from "components/CalenderCombination/CalenderCombination";
import { UseAppDispatch, UseAppSelector } from "store/hooks";
import {
  addAffectedMode,
  removeAffectedMode,
  setInternalName,
  setValidFromTime,
  setValidToTime,
} from "store/createTravelAdviceSlicer";
import moment from "moment";
import { dateTimeToISO } from "common/formatDate";
import useWindowDimensions from "common/UseWindowDimensions";
import Favorites from "components/Favorites/Favorites";
import { Screen } from "assets/Screen";
import Color from "assets/Color";
import { INTERNAL_NAME_LENGTH } from "assets/ValidInput";
/**
 *
 * @param props title of the page to distinguish between "edit"-mode and "create"-mode
 * @returns component consisting of all components needed to render the
 * "add travel advice"-functionality (without "override travel advice" and
 * "message to customer")
 */
export default function TravelInformation(props: { title: string }) {
  const appSelector = UseAppSelector((state) => state.createTravelAdvice);
  const dispatch = UseAppDispatch();
  const handleCheckChange = (value: string, unchecked: boolean) => {
    unchecked
      ? dispatch(removeAffectedMode(value))
      : dispatch(addAffectedMode(value));
  };
  const [fromTime, setFromTime] = useState(
    moment(new Date(appSelector.validFromTime)).format("HH:mm")
  );
  const [toTime, setToTime] = useState(
    moment(new Date(appSelector.validToTime)).format("HH:mm")
  );
  const [fromDate, setFromDate] = useState(
    moment(new Date(appSelector.validFromTime)).format("YYYY-MM-DD")
  );

  const [toDate, setToDate] = useState(
    moment(new Date(appSelector.validToTime)).format("YYYY-MM-DD")
  );

  const affectedModes = UseAppSelector(
    (state) => state.createTravelAdvice.affectedModes
  );
  const start = {
    time: fromTime,
    date: fromDate,
    setTime: setFromTime,
    setDate: setFromDate,
  };
  const end = {
    time: toTime,
    date: toDate,
    setTime: setToTime,
    setDate: setToDate,
  };

  useEffect(() => {
    dispatch(setValidFromTime(dateTimeToISO(fromDate, fromTime)));
  }, [fromTime, fromDate, dispatch]);

  useEffect(() => {
    dispatch(setValidToTime(dateTimeToISO(toDate, toTime)));
  }, [toTime, toDate, dispatch]);

  const { width: windowWidth } = useWindowDimensions();
  return (
    <>
      <Text pt="7px" textStyle="lg">
        {props.title}
      </Text>
      <RowElements>
        <div
          style={{
            minWidth:
              windowWidth > Screen.MAX_MOBILE_WIDTH
                ? Screen.PC_WIDTH
                : Screen.MOBILE_WIDTH,
          }}
        >
          <Text pt="6px" textStyle="sm">
            Navn*
          </Text>
          <FormControl
            isInvalid={appSelector.internalName.length > INTERNAL_NAME_LENGTH}
          >
            <Input
              value={appSelector.internalName}
              onChange={(event) =>
                dispatch(setInternalName(event.target.value))
              }
              label=""
            />
            <FormErrorMessage color={Color.invalidRed}>
              Navn må være mellom 1 og {INTERNAL_NAME_LENGTH} tegn, kan
              overstyres
            </FormErrorMessage>
          </FormControl>
        </div>
        {windowWidth > 660 && (
          <div style={{ paddingTop: "2rem", paddingLeft: "1rem" }}>
            <SimplePopover
              placement="right"
              triggerElement={<InformationOutline30Icon />}
            >
              Bruk iSirk-nummer hvis det eksisterer
            </SimplePopover>
          </div>
        )}
      </RowElements>
      <CalenderCombination start={start} end={end} />
      <div>
        <Text pb="6px" textStyle="sm">
          Påvirkede modaliteter
        </Text>
        <IconStack
          checkedTransports={affectedModes}
          handleCheckChange={handleCheckChange}
        />
      </div>
      <RowElements>
        <div
          style={{
            minWidth:
              windowWidth > Screen.MAX_MOBILE_WIDTH
                ? Screen.PC_WIDTH
                : Screen.MOBILE_WIDTH,
          }}
        >
          <RowElements style={{ width: "100%", marginBottom: "2%" }}>
            <Text textStyle="sm" marginRight={"25%"}>
              Fra stasjoner
            </Text>
            <Favorites selector={selector.fromStops}></Favorites>
          </RowElements>
          <SearchAndMultiSelect selector={selector.fromStops} />
        </div>

        <div
          style={{
            minWidth:
              windowWidth > Screen.MAX_MOBILE_WIDTH
                ? Screen.PC_WIDTH
                : Screen.MOBILE_WIDTH,
          }}
        >
          <RowElements style={{ width: "100%", marginBottom: "2%" }}>
            <Text textStyle="sm" marginRight={"27%"}>
              Til stasjoner
            </Text>
            <Favorites selector={selector.toStops}></Favorites>
          </RowElements>
          <SearchAndMultiSelect selector={selector.toStops} />
        </div>
      </RowElements>
    </>
  );
}
