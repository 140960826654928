import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  TextLink,
  FormControl,
  SearchInput,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  DeleteOutline24Icon,
} from "@vygruppen/spor-react";
import { useEffect, useState } from "react";
import { UseAppDispatch } from "store/hooks";
import { IFavorite, ILines, ISelector, IStation, selector } from "Types";
import { FixedSizeList as List } from "react-window";
import { addFromStop, addToStop } from "store/createTravelAdviceSlicer";
import {
  useGetTrainLinesQuery,
  useLazyGetStationsForLineQuery,
} from "services/travelDetailsApi";
import {
  useDeleteFavoriteMutation,
  useGetFavoritesQuery,
} from "services/favoritesApi";
import Color from "assets/Color";
import { toast } from "react-toastify";
import { SearchResults } from "Layouts/SearchResults/SearchResults";
import "assets/toast.css";
import Popup from "./popup";
/**
 *
 * @param props determines if component is applied to usecase "from stations" or "to stations".
 * @returns a modal component consisting of two tabs where a set of stations can be chosen from
 * either train lines or self-defined favorites, depending on the current tab.
 */
export default function Favorites(props: ISelector) {
  const [isOpen, setOpen] = useState(false);
  const [selectedLine, setSelectedLine] = useState<ILines>();
  const [selectedFavorite, setSelectedFavorite] = useState<IFavorite>();
  const [value, setValue] = useState("");
  const [mode, setMode] = useState<string>("favorites");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedForDelete, setSelectedForDelete] = useState<number>();

  const { data: lines = [] } = useGetTrainLinesQuery();
  const { data: favorites = [] } = useGetFavoritesQuery();
  const [
    deleteFavorite,
    {
      originalArgs: deleteFavoriteArgs,
      isSuccess: deleteSuccess,
      isError: deleteError,
      isLoading: deleteIsLoading,
    },
  ] = useDeleteFavoriteMutation();

  const [trigger, { data: lineStations, isSuccess, isFetching }] =
    useLazyGetStationsForLineQuery();

  const dispatch = UseAppDispatch();

  const ITEM_HEIGTH: number = 35;
  const MAX_SCROLL_HEIGHT: number = 300;

  /**
   * Renders toast for delete-operation
   */
  useEffect(() => {
    if (deleteSuccess) {
      toast("Favoritten er slettet", {
        type: "success",
        className: "successToast",
      });
      setSelectedForDelete(undefined);
      setSelectedFavorite(undefined);
    } else if (deleteError) {
      toast("Problemer oppsto ved sletting av favoritt", {
        type: "error",
        className: "errorToast",
      });
    }
  }, [deleteError, deleteSuccess]);

  /**
   * When the user clicks ok to apply favorite/line stations, the modal is closed
   * upon receiving a success indicator from the lineStation query
   */
  useEffect(() => {
    if (isSuccess) {
      switch (props.selector) {
        case selector.fromStops:
          if (selectedLine) {
            lineStations?.forEach((stop) => dispatch(addFromStop(stop)));
          }
          break;
        case selector.toStops:
          if (selectedLine) {
            lineStations?.forEach((stop) => dispatch(addToStop(stop)));
          }
          break;
      }
      setOpen(false);
    }
  }, [isSuccess && lineStations]);

  /**
   * When the modal is closed, the state of the tab and selected parameters
   * is reset to initial state so the modal is ready for applying more favorites/lines
   */
  useEffect(() => {
    if (!isOpen) close();
  }, [isOpen]);

  /**
   * When the user clicks ok, the respective stations of their choice is added to the
   * state representing the travel-advice "in the making"
   */
  function applyFavorite() {
    if (selectedLine && mode === "lines") {
      trigger(selectedLine.id);
    } else if (selectedFavorite && mode === "favorites") {
      switch (props.selector) {
        case selector.fromStops:
          const stopPlaces: IStation[] = selectedFavorite.stops;
          stopPlaces.forEach((stop) => dispatch(addFromStop(stop)));

          break;
        case selector.toStops:
          if (selectedFavorite) {
            const stopPlaces: IStation[] = selectedFavorite.stops;
            stopPlaces.forEach((stop) => dispatch(addToStop(stop)));
          }
          break;
      }
      setOpen(false);
    }
  }

  /**
   * Sets the currently selected "element to retrieve stations from"
   * @param id of the chosen list element, either of type favorite or train-line
   */
  function handleSelect(id: string | number) {
    if (typeof id === "string") {
      const line = lines.find((item) => item.id === id);
      if (line) {
        setSelectedLine(line);
      }
    } else {
      const favorite = favorites.find((favorite) => favorite.id === id);
      if (favorite) {
        setSelectedFavorite(favorite);
      }
    }
  }

  /**
   * removes element from currently selected "element to retrieve stations from"
   * @param id of the chosen list element, either of type favorite or train-line
   */
  function handleRemove(id: string | number) {
    if (typeof id === "string") {
      if (selectedLine && selectedLine.id === id) {
        setSelectedLine(undefined);
      }
    } else {
      if (selectedFavorite && selectedFavorite.id === id) {
        setSelectedFavorite(undefined);
      }
    }
  }

  /**
   *
   * @param param0 paged date, their relative index and their styling
   * @returns a list element as button for every item currently paged in
   */
  const Item = ({ data, index, style }: any) => {
    if (!data[index]) {
      return null;
    }
    let isFavorite = false;

    let selected;
    let listElements;

    switch (mode) {
      case "lines":
        selected = selectedLine === data[index];
        listElements = data[index].id;
        break;
      default:
        selected = selectedFavorite === data[index];
        listElements = data[index].name;
        isFavorite = true;
    }
    return (
      <Button
        style={style}
        className="selectBtn"
        flex="1"
        justifyContent="space-between"
        paddingLeft={"4%"}
        minW={"100%"}
        rightIcon={
          isFavorite ? (
            <DeleteOutline24Icon
              onClick={() => {
                {
                  setSelectedForDelete(data[index].id);
                  setIsPopupOpen(true);
                }
              }}
            ></DeleteOutline24Icon>
          ) : undefined
        }
        borderRadius={"0px"}
        type="button"
        variant={"ghost"}
        color={selected ? Color.selectedDropDownItem : Color.dropDownItem}
        background={
          selected ? Color.selectedItemBackground : Color.itemBackground
        }
        onClick={
          selected
            ? () => handleRemove(data[index].id)
            : () => handleSelect(data[index].id)
        }
        isLoading={deleteIsLoading && data[index].id === deleteFavoriteArgs}
      >
        {listElements}
      </Button>
    );
  };

  /**
   *
   * @param listMode determines if the list should render favorites or lines
   * @returns a scrollable and paged list consisting of clickable items
   */
  const renderListItems = (listMode: string) => {
    let match;
    switch (listMode) {
      case "favorites":
        match = favorites.filter((fav) =>
          fav.name.toLowerCase().includes(value.toLowerCase())
        );
        break;
      default:
        if (value === "") {
          return;
        }
        match = lines.filter(
          (line) =>
            (line.publicCode !== null &&
              line.publicCode.toLowerCase().includes(value.toLowerCase())) ||
            line.id.toLowerCase().includes(value.toLowerCase())
        );
        break;
    }
    const height =
      match.length * ITEM_HEIGTH < MAX_SCROLL_HEIGHT
        ? match.length * ITEM_HEIGTH
        : MAX_SCROLL_HEIGHT;

    return (
      lines.length > 0 && (
        <List
          className="list"
          itemData={match}
          height={height}
          itemCount={match.length}
          itemSize={ITEM_HEIGTH}
          width={"100%"}
        >
          {Item}
        </List>
      )
    );
  };

  /**
   * Resets the modals parameters so it is ready for applying more favorites/lines
   */
  function close() {
    setMode("favorites");
    setValue("");
    setSelectedFavorite(undefined);
    setSelectedLine(undefined);
    setSelectedForDelete(undefined);
  }

  function handleDelete(id: number) {
    deleteFavorite(id);
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <TextLink
          onClick={() => setOpen(true)}
          color="primary"
          variant={"secondary"}
        >
          Velg stasjoner fra...
        </TextLink>
      </div>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader marginBottom={"7%"}>Velg stasjoner fra</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs
              variant="square"
              colorScheme="dark"
              size="md"
              onChange={() => {
                mode === "lines" ? setMode("favorites") : setMode("lines");
              }}
              defaultIndex={0}
            >
              <TabList>
                <Tab>Favoritter</Tab>
                <Tab>Toglinjer</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Text paddingBottom={"4%"} paddingTop={"6%"}>
                    Velg favoritt
                  </Text>
                  <FormControl>
                    <SearchInput
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      onReset={() => setValue("")}
                    />
                  </FormControl>
                  <SearchResults>{renderListItems("favorites")}</SearchResults>
                </TabPanel>
                <TabPanel>
                  <Text paddingBottom={"4%"} paddingTop={"6%"}>
                    Velg toglinje
                  </Text>
                  <FormControl>
                    <SearchInput
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      onReset={() => setValue("")}
                    />
                  </FormControl>
                  <SearchResults>{renderListItems("lines")}</SearchResults>
                </TabPanel>
              </TabPanels>
            </Tabs>
            <Popup
              isOpen={isPopupOpen}
              setIsOpen={setIsPopupOpen}
              handleDelete={handleDelete}
              id={selectedForDelete}
            ></Popup>
          </ModalBody>
          <ModalFooter>
            <Button variant="tertiary" mr={3} onClick={() => setOpen(false)}>
              Lukk
            </Button>
            <Button
              variant="primary"
              mr={3}
              onClick={() => applyFavorite()}
              isLoading={isFetching}
              isDisabled={selectedFavorite || selectedLine ? false : true}
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
