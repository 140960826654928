import moment from "moment-timezone";

/**
 *
 * @param date to be formated
 * @returns date string in norwegian format
 */
export const formatDateToLocaleString = (date: Date): string => {
  return date.toLocaleDateString("nb-NO", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

/**
 *
 * @param date to be formated
 * @param time to be formated
 * @returns Concats date and time into format compatible to backend and miliseconds since epoch
 */
export const dateTimeToISO = (date: string, time: string) => {
  return moment(date + " " + time, "YYYY-MM-DD HH:mm")
    .tz("Europe/Oslo")
    .format();
};
