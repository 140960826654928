import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import "index.css";
import App from "App";
import { SporProvider, Language } from "@vygruppen/spor-react";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import { store } from "store/store";

const root = createRoot(document.getElementById("root")!);

root.render(
  <SporProvider language={Language.NorwegianBokmal}>
    <Provider store={store}>
      <App />
    </Provider>
  </SporProvider>
);

// Registers serviceWorker to enable offline usage.
// Possible to unregister by switching to .unregister()
serviceWorkerRegistration.register();
