import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFiltering } from "Types";

/**
 * Initial filter/sort parameters
 */
const initialState: IFiltering = {
  sortType: "navn",
  sortOrder: true,
  toDate: "",
  fromDate: "",
  affectedModes: [],
};

/**
 * Define reducers for manipulating the global state "data slice".
 * This slice is used when setting filter/sort parameters
 */
export const filteredTravelAdviceSlicer = createSlice({
  name: "filteredTravelAdviceSlicer",
  initialState,
  reducers: {
    setSortType: (state, action: PayloadAction<string>) => {
      state.sortType = action.payload;
    },
    setSortOrder: (state, action: PayloadAction<boolean>) => {
      state.sortOrder = action.payload;
    },
    setToDate: (state, action: PayloadAction<string>) => {
      state.toDate = action.payload;
    },
    setFromDate: (state, action: PayloadAction<string>) => {
      state.fromDate = action.payload;
    },
    addAffectedModes: (state, action: PayloadAction<string>) => {
      if (!state.affectedModes.includes(action.payload)) {
        state.affectedModes.push(action.payload);
      }
    },
    removeAffectedMode: (state, action: PayloadAction<string>) => {
      state.affectedModes = state.affectedModes.filter(
        (mode) => mode !== action.payload
      );
    },
  },
});

export const {
  setFromDate,
  setToDate,
  setSortOrder,
  setSortType,
  addAffectedModes,
  removeAffectedMode,
} = filteredTravelAdviceSlicer.actions;
export default filteredTravelAdviceSlicer.reducer;
