import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  IconButton,
  Text,
} from "@vygruppen/spor-react";
import { formatDateToLocaleString } from "common/formatDate";
import React, { useEffect, useState } from "react";
import { IIconButton, ITravelAdvice, ITravelDetail } from "Types";
import pencil from "../../assets/pencil.svg";
import document from "../../assets/document.svg";
import trashbin from "../../assets/trashbin.svg";
import { UseAppDispatch } from "store/hooks";
import { setNewState } from "store/createTravelAdviceSlicer";
import EditTravelAdvice from "components/EditTravelAdvice/EditTravelAdvice";
import { toast } from "react-toastify";
import {
  usePostTravelAdviceMutation,
  usePutTravelAdviceMutation,
} from "services/travelAdvicesApi";
import useWindowDimensions from "common/UseWindowDimensions";
import { DetailsGrid } from "Layouts/DetailsGrid/DetailsGrid";
import { TitleContainer } from "Layouts/TitleContainer/TitleContainer";
import "assets/toast.css";

/**
 *
 * @param travelAdviceData the travel advice to be displayed
 * @returns representation of a single travel advice in the travel advice overview list
 */
function TravelAdvice(travelAdviceData: ITravelAdvice) {
  const dispatch = UseAppDispatch();
  const [
    postTravelAdvice,
    { isSuccess: postSuccess, isError: postError, isLoading: postIsLoading },
  ] = usePostTravelAdviceMutation();
  const [
    putTravelAdvice,
    { isSuccess: putSuccess, isError: putError, isLoading: putIsLoading },
  ] = usePutTravelAdviceMutation();

  /**
   * Update the isArchived field of the travel advice in database
   */
  function archiveTravelAdvice() {
    const travelAdvice: ITravelAdvice = {
      ...travelAdviceData,
      isArchived: true,
    };
    putTravelAdvice(travelAdvice);
  }

  /**
   * Create a replica of the traveladvice. Is by default not archived.
   */
  function duplicate() {
    const duplicated: ITravelAdvice = {
      ...travelAdviceData,
      id: undefined,
      isArchived: false,
    };
    postTravelAdvice(duplicated);
  }

  /**
   * Toast for archiving
   */
  useEffect(() => {
    if (putSuccess) {
      toast("Reiserådet er arkivert", {
        type: "success",
        className: "successToast",
      });
    } else if (putError) {
      toast("Problemer oppsto ved arkivering av reiserådet", {
        type: "error",
        className: "errorToast",
      });
    }
  }, [putError, putSuccess]);

  /**
   * Toast for duplicating
   */
  useEffect(() => {
    if (postSuccess) {
      toast("Reiserådet er duplisert", {
        type: "success",
        className: "successToast",
      });
    } else if (postError) {
      toast("Problemer oppsto ved duplisering av reiserådet", {
        type: "error",
        className: "errorToast",
      });
    }
  }, [postError, postSuccess]);

  const [isOpen, setIsOpen] = useState(false);

  /**
   * Open edit-modal with current data for the travel advice already filled in
   * in the gui.
   */
  function handleEdit() {
    dispatch(setNewState(travelAdviceData));
    setIsOpen(true);
  }

  return (
    <AccordionItem>
      <AccordionButton>
        <Title {...travelAdviceData} />
      </AccordionButton>
      <AccordionPanel>
        <hr style={{ opacity: 0.2 }}></hr>
        <div style={{ display: "flex", justifyContent: "end", marginTop: 25 }}>
          <FlexibleBtn
            onClick={() => duplicate()}
            label="Dupliser"
            isLoading={postIsLoading}
            icon={<img src={document} alt="copy" />}
          />
          {!travelAdviceData.isArchived && (
            <>
              <EditTravelAdvice
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              ></EditTravelAdvice>
              <FlexibleBtn
                onClick={() => handleEdit()}
                label="Rediger"
                icon={<img src={pencil} alt="edit" />}
              />
              <FlexibleBtn
                onClick={() => archiveTravelAdvice()}
                isLoading={putIsLoading}
                label="Arkiver"
                icon={<img src={trashbin} alt="archive" />}
              />
            </>
          )}
        </div>
        <DetailsGrid>
          <TravelDetail
            detail="Fra stasjoner"
            value={travelAdviceData.fromStops
              .map((stop) => stop.name)
              .join(", ")}
          />
          <TravelDetail
            detail="Til stasjoner"
            value={travelAdviceData.toStops.map((stop) => stop.name).join(", ")}
          />
          <TravelDetail
            detail="Modaliteter"
            value={travelAdviceData.affectedModes.join(", ")}
          />
          <TravelDetail
            detail="Overstyrte modaliteter"
            value={travelAdviceData.neverAllowModes.join(", ")}
          />

          <TravelDetail
            detail="Ekskluder"
            value={
              travelAdviceData.neverAllowAuthorities.concat(
                travelAdviceData.neverAllowLines
              ).length > 0
                ? travelAdviceData.neverAllowAuthorities
                    .concat(travelAdviceData.neverAllowLines)
                    .join(", ")
                : ""
            }
          />
          <TravelDetail
            detail="Intern Kommentar"
            value={travelAdviceData.internalComment}
          />
          <TravelDetail
            detail="Norsk melding"
            title={
              travelAdviceData.texts.nob ? travelAdviceData.texts.nob.title : ""
            }
            value={
              travelAdviceData.texts.nob ? travelAdviceData.texts.nob.body : ""
            }
          />
          <TravelDetail
            detail="Svensk melding"
            title={
              travelAdviceData.texts.swe ? travelAdviceData.texts.swe.title : ""
            }
            value={
              travelAdviceData.texts.swe ? travelAdviceData.texts.swe.body : ""
            }
          />
          <TravelDetail
            detail="Engelsk melding"
            title={
              travelAdviceData.texts.eng ? travelAdviceData.texts.eng.title : ""
            }
            value={
              travelAdviceData.texts.eng ? travelAdviceData.texts.eng.body : ""
            }
          />
        </DetailsGrid>
      </AccordionPanel>
    </AccordionItem>
  );
}

const Title: React.FC<ITravelAdvice> = ({
  internalName,
  validFromTime,
  validToTime,
}: ITravelAdvice) => {
  return (
    <TitleContainer>
      <TravelDetail detail="Navn" value={internalName} />

      <TravelDetail
        detail="Fra"
        value={formatDateToLocaleString(new Date(validFromTime))}
      />

      <TravelDetail
        detail="Til"
        value={formatDateToLocaleString(new Date(validToTime))}
      />
    </TitleContainer>
  );
};

function TravelDetail({ detail, value, title }: ITravelDetail) {
  return (
    <div style={{ padding: "1rem", flexBasis: "33%" }}>
      <Text fontWeight="bold" marginBottom={"3%"}>
        {detail}
      </Text>
      {title ? (
        <Text fontWeight="550" fontStyle={"italic"}>
          {title}
        </Text>
      ) : null}
      <Text>{value}</Text>
    </div>
  );
}

function FlexibleBtn({ icon, label, onClick, isLoading = false }: IIconButton) {
  const { width } = useWindowDimensions();
  return (
    <>
      {width < 560 ? (
        <IconButton
          variant="additional"
          icon={icon}
          isLoading={isLoading}
          size="lg"
          marginX={1}
          aria-label={label}
          onClick={() => onClick()}
        />
      ) : (
        <Button
          variant="additional"
          isLoading={isLoading}
          marginX={1}
          leftIcon={icon}
          aria-label={label}
          onClick={() => onClick()}
        >
          {label}
        </Button>
      )}
    </>
  );
}

export default TravelAdvice;
