import { Accordion, ContentLoader, Stack } from "@vygruppen/spor-react";
import { UseAppSelector } from "store/hooks";
import { ISearchData, ITravelAdvice } from "Types";
import TravelAdvice from "../TravelAdvice/TravelAdvice";
import { useGetLinesQuery } from "services/travelDetailsApi";
import { useGetTravelAdvicesQuery } from "services/travelAdvicesApi";

/**
 *
 * @param props search word and type of list.
 * @returns a component representing a list of all travel advices. Renders only
 * travel advices matching the search word, with either "archived" or "active" status
 */
function TravelAdviceList(props: ISearchData) {
  const selector = UseAppSelector((state) => state.filters);
  let travelAdviceSelector: ITravelAdvice[] = [];

  const { data: linesList = [] } = useGetLinesQuery();

  const { data: allTravelAdvices = [], isLoading: getTravelAdvicesLoading } =
    useGetTravelAdvicesQuery();
  switch (props.view) {
    case "archived":
      travelAdviceSelector = allTravelAdvices.filter(
        (advice) => advice.isArchived
      );

      break;
    case "active":
      travelAdviceSelector = allTravelAdvices.filter(
        (advice) => !advice.isArchived
      );
      break;
  }

  /**
   * Handles sorting of the list
   */
  const sortType = (a: ITravelAdvice, b: ITravelAdvice) => {
    const t1 = selector.sortOrder ? a : b;
    const t2 = selector.sortOrder ? b : a;

    switch (selector.sortType) {
      case "startDate":
        return (
          new Date(t1.validFromTime).valueOf() -
          new Date(t2.validFromTime).valueOf()
        );
      case "endDate":
        return (
          new Date(t1.validToTime).valueOf() -
          new Date(t2.validToTime).valueOf()
        );
      default:
        return t1.internalComment.localeCompare(t2.internalComment);
    }
  };

  /**
   * Handles filtering on date
   */
  const filterDate = (data: ITravelAdvice) => {
    return (
      (selector.fromDate === "" && selector.toDate === "") ||
      (new Date(data.validFromTime) >= new Date(selector.fromDate) &&
        selector.toDate === "") ||
      (new Date(data.validToTime) <= new Date(selector.toDate) &&
        selector.fromDate === "") ||
      (new Date(data.validToTime) <= new Date(selector.toDate) &&
        new Date(data.validFromTime) >= new Date(selector.fromDate))
    );
  };
  const filterSearch = (data: ITravelAdvice) => {
    const lines = linesList
      .filter((item) => data.neverAllowLines.includes(item.id))
      .map((item) => item.publicCode);
    return (
      props.searchText === "" ||
      data.internalName
        ?.toString()
        ?.toLowerCase()
        ?.includes(props.searchText.toLowerCase()) ||
      data.internalComment
        ?.toString()
        ?.toLowerCase()
        ?.includes(props.searchText.toLowerCase()) ||
      lines.find((line) =>
        line?.toLowerCase().includes(props.searchText.toLowerCase())
      )
    );
  };

  const filterModes = (data: ITravelAdvice) => {
    return (
      selector.affectedModes.length < 1 ||
      selector.affectedModes.every((mode) => data.affectedModes.includes(mode))
    );
  };

  return (
    <Accordion variant="card" allowToggle={true}>
      <Stack spacing={5}>
        {!getTravelAdvicesLoading ? (
          travelAdviceSelector
            .filter(
              (travelAdvice) =>
                filterSearch(travelAdvice) &&
                filterModes(travelAdvice) &&
                filterDate(travelAdvice)
            )
            .sort((t1, t2) => sortType(t1, t2))

            .map((travelAdvice: ITravelAdvice, index: number) => (
              <TravelAdvice key={"TravelAdvice: " + index} {...travelAdvice} />
            ))
        ) : (
          <ContentLoader>Henter reiseråd</ContentLoader>
        )}
      </Stack>
    </Accordion>
  );
}

export default TravelAdviceList;
