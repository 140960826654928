import { IFavorite, ISelector, selector } from "Types";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TextLink,
  Button,
  Input,
  FormControl,
  FormErrorMessage,
} from "@vygruppen/spor-react";
import { useEffect, useState } from "react";
import { UseAppSelector } from "store/hooks";
import { usePostFavoriteMutation } from "services/favoritesApi";
import { toast } from "react-toastify";
import "assets/toast.css";
import Color from "assets/Color";
import { FAVORITE_NAME_LENGTH } from "assets/ValidInput";

export default function SaveAsFavorite(props: ISelector) {
  const [isOpen, setOpen] = useState(false);
  const [name, setName] = useState("");
  const selectedFromStops = UseAppSelector(
    (state) => state.createTravelAdvice.fromStops
  );
  const selectedToStops = UseAppSelector(
    (state) => state.createTravelAdvice.toStops
  );

  /**
   * Posts the favorit to the database
   */
  function saveFavorite() {
    let favorite: IFavorite;
    if (props.selector === selector.fromStops) {
      favorite = { name: name, stops: selectedFromStops };
    } else {
      favorite = { name: name, stops: selectedToStops };
    }

    postFavorite(favorite);
  }
  const [
    postFavorite,
    { isSuccess: postSuccess, isError: postError, isLoading },
  ] = usePostFavoriteMutation();

  /**
   * Toast for create favorite
   */
  useEffect(() => {
    if (postSuccess) {
      toast("Favoritten er lagt til", {
        type: "success",
        className: "successToast",
      });
      setName("");
      setOpen(false);
    } else if (postError) {
      toast("Problemer oppsto ved oppretting av favoritt", {
        type: "error",
        className: "errorToast",
      });
    }
  }, [postError, postSuccess]);

  return (
    <>
      <TextLink onClick={() => setOpen(true)} size={"xs"}>
        Lagre stasjoner som favoritt
      </TextLink>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader marginBottom={"7%"}>
            Lagre stasjoner som favoritt
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl
              marginBottom={"7%"}
              isInvalid={name.length > FAVORITE_NAME_LENGTH}
            >
              <Input
                label="Navn på favoritt"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onReset={() => setName("")}
              ></Input>
              <FormErrorMessage color={Color.invalidRed}>
                Navnet må være mellom 1 og {FAVORITE_NAME_LENGTH} tegn, kan
                overstyres
              </FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="tertiary" mr={3} onClick={() => setOpen(false)}>
              Avbryt
            </Button>
            <Button
              variant="primary"
              mr={3}
              onClick={() => saveFavorite()}
              isLoading={isLoading}
              isDisabled={name === ""}
            >
              Lagre
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
