import {
  Checkbox,
  FilterOutline30Icon,
  IconButton,
  Input,
  TextLink,
  SimplePopover,
  Text,
  Select,
  TrainOutline24Icon,
  TrainFill24Icon,
  Stack,
  BusOutline24Icon,
  BusFill24Icon,
  TramOutline24Icon,
  TramFill24Icon,
  SubwayOutline24Icon,
  SubwayFill24Icon,
  FerryOutline24Icon,
  FerryFill24Icon,
  ChoiceChip,
} from "@vygruppen/spor-react";
import { RowElements } from "Layouts/RowElements/RowElements";
import { useState } from "react";
import { UseAppDispatch, UseAppSelector } from "store/hooks";
import {
  setSortOrder,
  setSortType,
  setFromDate,
  setToDate,
  addAffectedModes,
  removeAffectedMode,
} from "store/filteredTravelAdviceSlicer";

/**
 *
 * @returns component for filtering the list overview of all travel advices.
 * Can filter on transport mode and date, as well as sorting cronologically on
 * name and date
 */
export default function Filter() {
  const dispatch = UseAppDispatch();
  const selector = UseAppSelector((state) => state.filters);

  const [checkedBoat, setCheckedBoat] = useState(
    selector.affectedModes.includes("Båt")
  );
  const [checkedSubway, setCheckedSubway] = useState(
    selector.affectedModes.includes("T-bane")
  );
  const [checkedTram, setCheckedTram] = useState(
    selector.affectedModes.includes("Trikk")
  );
  const [checkedBus, setCheckedBus] = useState(
    selector.affectedModes.includes("Buss")
  );
  const [checkedTrain, setCheckedTrain] = useState(
    selector.affectedModes.includes("Tog")
  );
  const [displayedToDate, setDisplayedToDate] = useState(selector.toDate);
  const [displayedFromDate, setDisplayedFromDate] = useState(selector.fromDate);

  /**
   * Resets the filter parameters
   */
  const handleReset = () => {
    setCheckedBoat(false);
    handleCheckChange("Båt", true);
    setCheckedSubway(false);
    handleCheckChange("T-bane", true);
    setCheckedTram(false);
    handleCheckChange("Trikk", true);
    setCheckedBus(false);
    handleCheckChange("Buss", true);
    setCheckedTrain(false);
    handleCheckChange("Tog", true);
    setDisplayedToDate("");
    dispatch(setToDate(""));
    setDisplayedFromDate("");
    dispatch(setFromDate(""));
  };

  /**
   * Handles toggling of transport modes
   * @param value of the togled transport mode
   * @param checked determines if value is set to checked or unchecked
   */
  const handleCheckChange = (value: string, checked: boolean) => {
    if (checked) {
      dispatch(removeAffectedMode(value));
    } else {
      dispatch(addAffectedModes(value));
    }
  };

  return (
    <SimplePopover
      placement="bottom"
      variant="tertiary"
      size="lg"
      triggerElement={
        <IconButton
          variant="secondary"
          size="lg"
          icon={<FilterOutline30Icon />}
          aria-label="Filtrer"
        />
      }
    >
      <div>
        <Text size={"sm"} paddingTop={"1rem"}>
          Inkluder modaliteter
        </Text>
        <Stack direction="column">
          <Stack direction="row">
            <ChoiceChip
              isChecked={checkedTrain}
              onChange={() => {
                setCheckedTrain(!checkedTrain);
                handleCheckChange("Tog", checkedTrain);
              }}
              icon={{
                default: <TrainOutline24Icon />,
                checked: <TrainFill24Icon />,
              }}
            >
              Tog
            </ChoiceChip>

            <ChoiceChip
              isChecked={checkedBus}
              onChange={() => {
                setCheckedBus(!checkedBus);
                handleCheckChange("Buss", checkedBus);
              }}
              icon={{
                default: <BusOutline24Icon />,
                checked: <BusFill24Icon />,
              }}
            >
              Buss
            </ChoiceChip>

            <ChoiceChip
              isChecked={checkedTram}
              onChange={() => {
                setCheckedTram(!checkedTram);
                handleCheckChange("Trikk", checkedTram);
              }}
              icon={{
                default: <TramOutline24Icon />,
                checked: <TramFill24Icon />,
              }}
            >
              Trikk
            </ChoiceChip>
          </Stack>
          <Stack direction="row">
            <ChoiceChip
              isChecked={checkedSubway}
              onChange={() => {
                setCheckedSubway(!checkedSubway);
                handleCheckChange("T-bane", checkedSubway);
              }}
              icon={{
                default: <SubwayOutline24Icon />,
                checked: <SubwayFill24Icon />,
              }}
            >
              T-bane
            </ChoiceChip>

            <ChoiceChip
              isChecked={checkedBoat}
              onChange={() => {
                setCheckedBoat(!checkedBoat);
                handleCheckChange("Båt", checkedBoat);
              }}
              icon={{
                default: <FerryOutline24Icon />,
                checked: <FerryFill24Icon />,
              }}
            >
              Båt
            </ChoiceChip>
          </Stack>
        </Stack>
        <Text size={"sm"} paddingTop={"1rem"}>
          Inkluder reiser fra dato
        </Text>
        <Input
          value={displayedFromDate}
          onChange={(event) => {
            dispatch(setFromDate(event.target.value));
            setDisplayedFromDate(event.target.value);
          }}
          type="date"
          label="Dato"
          width="176px"
          textColor={"black"}
        />
        <Text paddingTop={"1rem"}>Inkluder reiser til dato</Text>
        <Input
          value={displayedToDate}
          onChange={(event) => {
            dispatch(setToDate(event.target.value));
            setDisplayedToDate(event.target.value);
          }}
          type="date"
          label="Dato"
          width="176px"
          textColor={"black"}
        />
        <RowElements>
          <div>
            <Text paddingTop={"1rem"}>Sorter på:</Text>
            <Select
              textColor={"black"}
              label=""
              onChange={(e) => dispatch(setSortType(e.target.value))}
            >
              <option value="name">Navn</option>
              <option value="startDate">Startdato</option>
              <option value="endDate">Sluttdato</option>
            </Select>
          </div>
          <Checkbox
            defaultChecked
            onChange={(e) => dispatch(setSortOrder(e.target.checked))}
          >
            Kronologisk
          </Checkbox>
        </RowElements>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextLink
            paddingTop={"1rem"}
            onClick={() => handleReset()}
            color="primary"
          >
            Nullstill
          </TextLink>
        </div>
      </div>
    </SimplePopover>
  );
}
