import { configureStore } from "@reduxjs/toolkit";
import createTravelAdviceSlicer from "./createTravelAdviceSlicer";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import filteredTravelAdviceSlicer from "./filteredTravelAdviceSlicer";
import sessionStorage from "redux-persist/lib/storage/session";
import { travelDetailsApi } from "services/travelDetailsApi";
import { travelAdvicesApi } from "services/travelAdvicesApi";
import { favoritesApi } from "services/favoritesApi";

/**
 * Setup for Redux global state.
 */

const persistConfig = {
  key: "root",
  version: 1,
  whitelist: ["createTravelAdvice"],
  storage: sessionStorage,
};

const rootReducer = combineReducers({
  [travelDetailsApi.reducerPath]: travelDetailsApi.reducer,
  [travelAdvicesApi.reducerPath]: travelAdvicesApi.reducer,
  [favoritesApi.reducerPath]: favoritesApi.reducer,
  createTravelAdvice: createTravelAdviceSlicer,
  filters: filteredTravelAdviceSlicer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(travelDetailsApi.middleware)
      .concat(travelAdvicesApi.middleware)
      .concat(favoritesApi.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
